import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { getById, getByRelatedCategory, getAll } from "../../API/Api";
import BlogColumn from "./BlogColumn";
import FooterBlogs from "./FooterBlogs";

function BlogSingle() {
  const [scrollTop, setScrollTop] = useState(0);
  const [visible, setVisible] = useState("");
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [blog, setBlog] = useState({});
  const [loader, setLoader] = useState(false);
  const [relatedBlogsLoader, setRelatedBlogsLoader] = useState(false);
  const [latestBlogsLoader, setLatestBlogsLoader] = useState(false);

  const { id } = useParams("id");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [id]);

  useEffect(() => {
    setTimeout(() => {
      setVisible("visible");
    }, 500);

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
  };

  useEffect(() => {
    if (!!id) {
      setLoader(true);
      getById("Blogs", id)
        .then((res) => {
          setBlog({ ...res?.data[0] });
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [id]);

  useEffect(() => {
    if (blog?.category !== undefined) {
      setRelatedBlogsLoader(true);
      getByRelatedCategory("Blogs", blog?.id, blog?.category, 3)
        .then((res) => {
          setRelatedBlogs([...res?.data]);
          setRelatedBlogsLoader(false);
        })
        .catch(() => {
          setRelatedBlogsLoader(false);
        });
    }
  }, [blog]);

  useEffect(() => {
    setLatestBlogsLoader(true);
    getAll("Blogs", 3)
      .then((res) => {
        setLatestBlogs([...res?.data]);
        setLatestBlogsLoader(false);
      })
      .catch((err) => {
        setLatestBlogsLoader(false);
      });
  }, []);

  return (
    <>
      {/*<!-- start of page-header -->*/}
      <section className="page-header pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <div className="mt-0 mt-lg-4">
                <p className={`mb-3 font-weight-500 has-anim ${visible} fade`}>
                  {blog?.category}{" "}
                  <span className="mx-2 d-inline-block">━</span> {blog?.author}
                </p>
                <h2
                  className={`text-white font-weight-500 has-anim ${visible} fade anim-delay-1`}
                >
                  {blog?.title}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- end of page-header -->*/}

      <div className="spinner">
        {!!loader && (
          <TailSpin
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        )}
      </div>

      {/*<!-- end of portfolio-details -->*/}
      {!loader && (
        <section className="section pb-0">
          <div className="container">
            <div className="row">
              <div
                className={`col-12 mb-n5 has-anim ${visible} anim-delay-2 center-image-flex`}
              >
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={
                    blog?.image
                      ? `https://test.silem.co/assets/${blog?.image}`
                      : ""
                  }
                  alt="blog thumb"
                />
              </div>
            </div>
          </div>

          <div className="section bg-white">
            <div className="container">
              <div className="row justify-content-center pt-5">
                <div className="col-lg-9">
                  <div className="pr-0 pr-lg-5">
                    <div className="content mb-5 pb-3">
                      <div
                        dangerouslySetInnerHTML={{ __html: blog?.detail }}
                      ></div>
                      {/*<p className="lead">{blog?.detail}</p>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {(!!latestBlogs?.length || !!latestBlogsLoader) && (
            <FooterBlogs
              title={"Latest Blogs"}
              Blogs={latestBlogs}
              visible={visible}
              loader={latestBlogsLoader}
            />
          )}

          {(!!relatedBlogs?.length || !!relatedBlogsLoader) && (
            <FooterBlogs
              title={"Related Blogs"}
              Blogs={relatedBlogs}
              visible={visible}
              loader={relatedBlogsLoader}
            />
          )}
        </section>
      )}
      {/*<!-- end of portfolio-details -->*/}
    </>
  );
}

export default BlogSingle;
