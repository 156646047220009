import { BrowserRouter, Routes, Route } from "react-router-dom";
import DigitalAgency from "../components/home/DigitalAgency";
import Portfolio from "../components/portfolio/Portfolio";
import PortfolioSingle from "../components/portfolio/PortfolioSingle";
import Blogs from "../components/blogs/Blogs";
import BlogSingle from "../components/blogs/BlogSingle";
import Contact from "../components/about/Contact";
import Team from "../components/about/Team";
import Services from "../components/services/Services";
import Body from "../Body";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route paht="/" element={<Body />}>
          <Route index element={<DigitalAgency />}></Route>
          <Route path="portfolio" element={<Portfolio />}></Route>
          <Route
            path="/portfolio/single-portfolio"
            element={<PortfolioSingle />}
          ></Route>
          <Route path="blogs" element={<Blogs />}></Route>
          <Route path="/blog/:id" element={<BlogSingle />}></Route>
          <Route path="contact" element={<Contact />}></Route>
          <Route path="team" element={<Team />}></Route>
          <Route path="services" element={<Services />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
