import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const [pinNavbar, setPinNavbar] = useState(true);
  const [scrollTop, setScrollTop] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);

      setPinNavbar(
        e.target.documentElement.scrollTop < 300 ||
          e.target.documentElement.scrollTop < scrollTop
      );
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <>
      {/*start of navigation */}
      <div className="adjust-navigation-height"></div>

      <header className={`navigation ${!pinNavbar ? "header-unpinned" : ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar navbar-expand-lg navbar-dark px-0 py-3 py-lg-4">
                {/*navbar logo */}
                <Link
                  className="navbar-brand font-weight-bold mb-0"
                  to="/"
                  title="Design'em"
                >
                  <img
                    src={require("../../assets/images/designem-logo.png")}
                    alt="Design'em"
                  />
                </Link>

                {/*navbar-toggler */}
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="navbar-menu"
                >
                  <svg viewBox="0 0 341.333 341.333">
                    {" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <rect
                          y="277.333"
                          width="341.333"
                          height="42.667"
                        />{" "}
                      </g>{" "}
                    </g>{" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <rect
                          y="149.333"
                          width="341.333"
                          height="42.667"
                        />{" "}
                      </g>{" "}
                    </g>{" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <rect y="21.333" width="341.333" height="42.667" />{" "}
                      </g>{" "}
                    </g>{" "}
                    <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{" "}
                    <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{" "}
                    <g> </g> <g> </g> <g> </g>{" "}
                  </svg>
                </button>

                {/*sidenav-overlay */}
                <div className="sidenav-overlay"></div>

                {/*navbar links */}
                <div className="navbar-collapse" id="navbar-menu">
                  <ul className="navbar-nav mx-auto">
                    <li data-toggle="navbar-menu-close">
                      <i className="las la-times mr-1"></i>Close
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/">
                        Home
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="services">
                        Services
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="portfolio">
                        Portfolio
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="blogs">
                        Blogs
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link">Pricing</Link>
                    </li>

                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        About
                      </Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link className="dropdown-item">About Us</Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="team">
                            Our Team
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="contact">
                            Contact
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/*end of navigation */}
    </>
  );
}

export default Navbar;
