import React from "react";
import { Link } from "react-router-dom";

function BlogColumn({ visible, blog }) {
  return (
    <div className="col-lg-4 col-md-6">
      <article
        className={`card blog-card border-0 rounded-0 has-anim ${visible}`}
      >
        <div
          className="card-background"
          style={{ backgroundColor: "#0a11a8" }}
        ></div>

        <Link to={`/blog/${blog?.id}`} className="card-image">
          <img
            loading="lazy"
            src={`https://test.silem.co/assets/${blog?.image}`}
            className="card-img rounded-0"
            alt="blog-post"
          />
        </Link>

        <div className="card-body p-0 mt-4">
          <span className="card-tag mb-3">
            <Link to={`/blog/${blog?.id}`}>{blog?.category}</Link>
          </span>

          <h4 className="card-title">
            <Link to={`/blog/${blog?.id}`}>{blog?.title}</Link>
          </h4>
          <p className="card-text">{blog?.details}</p>

          <p className="card-meta mt-3">{blog?.author}</p>
        </div>
      </article>
    </div>
  );
}

export default BlogColumn;
