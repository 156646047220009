import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./modules/navbar/Navbar";
import ScrollProgressBar from "./modules/scrollProgressBar/ScrollProgressBar";
import Footer from "./modules/footer/Footer";
import FooterBottom from "./modules/footerBottom/FooterBottom";

function Body() {
  return (
    <>
      <Navbar />
      <ScrollProgressBar />
      <Outlet />
      <Footer />
      <FooterBottom />
    </>
  );
}

export default Body;
