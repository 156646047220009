import { get } from "./directus";

export const getAll = (content, limit = -1) =>
  get(content, {
    limit,
    sort: "sort,-date_created",
  });
  
export const getById = (content, id) =>
  get(content, {
    filter: {
      id,
    },
  });

export const getByCategory = (content, category) =>
  get(content, {
    filter: {
      category: {
        _in: category,
      },
    },
  });

export const getByRelatedCategory = (content, id, category, limit = -1) =>
  get(content, {
    limit,
    sort: "sort,-date_created",
    filter: {
      category: {
        _in: category,
      },
      id: {
        _neq: id,
      },
    },
  });

export const getByFilter = (content, filter) =>
  get(content, {
    sort: "sort,-date_created",
    filter: {
      _or: [
        {
          details: {
            _contains: filter,
          },
        },

        {
          title: {
            _contains: filter,
          },
        },
      ],
    },
  });
